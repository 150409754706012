<script setup lang="ts">
import { computed } from 'vue'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useConfirmComponent } from '/~/modals/confirm/confirm'
import {
  Account,
  usePointsPrograms,
} from '/~/templates/bill-payments/composables'

const props = withDefaults(
  defineProps<{
    account: Account & { icon: string; description: string; number: string }
    title: string
    description: string
    showConfirm?: boolean
    showCancel?: boolean
    onConfirm?: () => Promise<void>
    onCancel?: () => Promise<void>
  }>(),
  {
    showConfirm: true,
    showCancel: true,
  }
)

const { getAccountFullName } = usePointsPrograms()

const { confirming, cancelling, loading, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
    hide,
  })

function hide() {
  modal.hide()
}

const fullName = computed(() => getAccountFullName(props.account))
</script>

<template>
  <base-mdl width="sm" border-radius="xl" :padding="false" :closable="!loading">
    <template #header>
      <div class="relative border-b border-eonx-neutral-400 p-6 text-center">
        <span class="absolute right-3 top-6 xs:right-7">
          <base-button
            icon="heroicons/outline/x-mark"
            :size="32"
            :disabled="loading"
            @click="onCancel"
          />
        </span>
        <h2 class="text-center text-2xl leading-7 text-eonx-neutral-800">
          {{ title }}
        </h2>
      </div>
    </template>
    <div class="mt-8 flex w-full items-center px-5">
      <div class="mr-3 flex w-28 items-center justify-center">
        <base-image class="h-full w-full" :src="account.icon" :ratio="false" />
      </div>
      <div class="flex-col">
        <div>
          <div class="font-bold">
            {{ fullName }}
          </div>
          <div v-if="account.description" class="text-sm text-neutral-400">
            {{ account.description }}
          </div>
        </div>
        <div v-if="account.number" class="text-sm text-eonx-neutral-600">
          {{ account.number }}
        </div>
      </div>
    </div>
    <div class="mb-6 mt-5 space-y-5 px-5">
      <div v-html="description" />
    </div>
    <div class="border-t border-eonx-neutral-400 p-5">
      <div
        v-if="showConfirm || showCancel"
        :class="{ 'grid grid-cols-2 gap-2': showConfirm && showCancel }"
      >
        <base-button
          v-if="showCancel"
          :look="showConfirm ? 'outlined-color' : 'filled'"
          class="w-full"
          :disabled="loading"
          :loading="cancelling"
          @click="onCancel"
        >
          Cancel
        </base-button>
        <base-button
          v-if="showConfirm"
          class="w-full"
          :disabled="loading"
          :loading="confirming"
          @click="showCancel ? onConfirm() : hide()"
        >
          {{ showCancel ? 'Confirm' : 'Done' }}
        </base-button>
      </div>
    </div>
  </base-mdl>
</template>
